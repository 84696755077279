var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"z-index-lg",attrs:{"persistent":"","max-width":"90%","scrollable":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"grey lighten-3 pa-0 ma-0",staticStyle:{"z-index":"10000 !important"}},[_c('v-list',{staticClass:"fill-width"},[_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"text-h5 font-weight-bold primary--text"},[_vm._v("Detalles de Incidencias")])]),_c('v-list-item-action',[_c('v-btn',{staticStyle:{"position":"absolute","right":"15px","top":"5px"},attrs:{"icon":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-data-table',{staticClass:"table-pagos elevation-1",attrs:{"headers":_vm.header,"items":_vm.items,"footer-props":{itemsPerPageText: 'Mostrar'}},scopedSlots:_vm._u([{key:"item.cobro_planificado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(item.cobro_planificado.toFixed(2))}})]}},{key:"item.cobro_fuera_visita_transferencia",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(item.cobro_fuera_visita_transferencia.toFixed(2))}})]}},{key:"item.cobro_fuera_visita_efectivo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(item.cobro_fuera_visita_efectivo.toFixed(2))}})]}},{key:"item.cajas",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.cajas)}})]}},{key:"item.visitas_totales",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.visitas_totales)}})]}},{key:"item.RDV",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold ml-1",domProps:{"textContent":_vm._s('('+ item.co_ven + ') ')}}),_c('span',{staticClass:"blue-grey--text font-weight-medium",class:{'display': !_vm.$vuetify.breakpoint.mobile, 'subtitle-1': _vm.$vuetify.breakpoint.mobile },domProps:{"textContent":_vm._s(item.RDV)}})]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-4"},[_c('span',{staticClass:"blue-grey--text font-italic"},[_vm._v("No se encontraron Incidencias")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }