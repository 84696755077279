<template>
  <v-dialog
    v-model="modalShow"
    persistent
    max-width="90%"
    scrollable
    class="z-index-lg"
  >
    <v-card tile>
      <v-card-title class="grey lighten-3 pa-0 ma-0" style="z-index: 10000 !important">
        <v-list class="fill-width">
          <v-list-item>
            <v-list-item-content>
              <span class="text-h5 font-weight-bold primary--text">Detalles de Incidencias</span>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="cerrar()" style="position: absolute; right:15px; top: 5px">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-title>
      <v-card-text class="px-0 pb-0">
        <v-data-table
        class="table-pagos elevation-1"
        :headers="header"
        :items="items"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        >
        <template v-slot:item.cobro_planificado="{ item }">
          <span class="font-weight-medium" v-text="item.cobro_planificado.toFixed(2)" />
       </template>
        <template v-slot:item.cobro_fuera_visita_transferencia="{ item }">
          <span class="font-weight-medium" v-text="item.cobro_fuera_visita_transferencia.toFixed(2)" />
       </template>
        <template v-slot:item.cobro_fuera_visita_efectivo="{ item }">
          <span class="font-weight-medium" v-text="item.cobro_fuera_visita_efectivo.toFixed(2)" />
       </template>
        <template v-slot:item.cajas="{ item }">
          <span class="primary--text font-weight-bold" v-text="item.cajas" />
       </template>
        <template v-slot:item.visitas_totales="{ item }">
          <span class="primary--text font-weight-bold" v-text="item.visitas_totales" />
       </template>
        <template v-slot:item.RDV="{item}">
          <span
            class="font-weight-bold ml-1"
            v-text="'('+ item.co_ven + ') '" />
          <span
            class="blue-grey--text font-weight-medium"
            :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
            v-text="item.RDV" />
        </template>
        <template #loading>
          <v-skeleton-loader
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
        <template v-slot:no-data>
          <div class="text-center pa-4">
            <span class="blue-grey--text font-italic">No se encontraron Incidencias</span>
          </div>
        </template>
      </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name:'ModalDetallesPedidos',
  props:{
    value: Boolean,
    items:{
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      modalShow: this.value,
    }
  },
  watch: {
    modalShow (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.modalShow = val
    },
  },
  computed:{
    header () {
      return [
        {
          text: 'Vendedor',
          align: 'start',
          value: 'RDV',
          class: 'header-data',
          // width: '220'
        },
        { text: 'Visitas', value: 'visitas_totales', class: 'header-data', width: '100' },
        { text: 'Hora Inicio', value: 'hora_inicio_visita', class: 'header-data px-0', width: '130'},
        { text: 'Hora Final', value: 'hora_fin_ultima_visita', class: 'header-data', width: '130'},
        { text: 'Cajas', value: 'cajas', class: 'header-data', align: ' text-center', width: '90' },
        { text: 'Cobro Efectivo', value: 'cobro_fuera_visita_efectivo', class: 'header-data px-0', align: ' text-center',},
        { text: 'Cobro Transferencia', value: 'cobro_fuera_visita_transferencia', class: 'header-data', align: ' text-center' },
        { text: 'Cobro Planificado', value: 'cobro_planificado', class: 'header-data', align: ' text-center' },
      ]
    },
  },
  methods: {
    cerrar () {
      this.modalShow = false;
    },
  },
  filters: {
    formatNumero(num) {
      return num !== null && num !== 'null' ? num.replace(/,/, '.') : '';
    },
    precio_venta (price) {
      if(price != null) {
        const numbreLista = price.toString().split('');
        return numbreLista[1];
      }

      return price;
    }
  }
}
</script>
<style lang="sass">
.z-index-lg
  z-index: 1000 !important
</style>
